<template>
  <div>
    <v-dialog v-model="transformationsFromToAdvancedModal" persistent max-width="1200">
      <v-card class="pa-5 modalLayoutTransformationsTopCenterFromTo">
        <div style="max-height: 800px; overflow-y: auto; overflow-x: hidden;">
          <v-card-title class="white--text" style="position: sticky; top: 0; background-color: #fff; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);">
            <v-row>
              <v-col cols="12">
                <label class="primary--text font-weight-bold"> Configuração da Transformação: <label class="title">{{ 'De-para Avançado' }}</label></label><br>
                <label class="primary--text font-weight-bold"> Coluna Comparada: <label class="title">{{ columnName }}</label></label>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-subtitle class="pa-0 ma-0 mb-5" style="overflow-y: auto;">
            <v-row class="pa-0 ma-0">
              <v-col class="px-0 py-0 mt-5" cols="12">
                <v-data-table
                  class="elevation-2 px-3"
                  show-expand
                  :expanded.sync="expanded"
                  dense
                  loading-text="Carregando..."
                  :headers="headers"
                  :items="parametersFromToAdvanced"
                  :items-per-page="-1"
                  :single-expand="true"
                  item-key="order"
                  :height="parametersFromToAdvanced && parametersFromToAdvanced.length >= 5 ? '300px' : ''"
                  hide-default-footer
                  hide-default-header
                  fixed-header
                >
                  <template v-slot:top>
                    <v-toolbar flat short style="background-color: transparent !important">
                      <v-col align="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="addParameter()"
                              v-if="!isVisualization"
                            >
                              <v-icon>
                                fas fa-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Adicionar Coluna</span>
                        </v-tooltip>
                      </v-col>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`header`]="{ props }">
                    <thead>
                      <tr>
                        <th :style="getThStyle(index)" v-for="(header, index) in props.headers" :key="header.text">
                          <label class="label primary--text">
                            {{ header.text }}
                          </label>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:no-data>
                    <span>Não há dados</span>
                  </template>
                  <template v-slot:[`item.value`]="{ item }">
                    <v-row
                      class="pt-2"
                      no-gutters>
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12">
                        <div
                          class="d-flex flex-row align-center"
                          :id="['', null, undefined].some(el => el === item.value)
                          ? 'fromToAdvanceActiveField'
                          : ''">
                          <label
                            class="text-subtitle-1 pr-3">
                            Valor do Original:
                          </label>
                          <v-autocomplete
                            v-model="item.columnValue"
                            :items="layoutSession.typeIntegrationValue === 'SHIPPING' ? dataSourceColumns : layout.columns"
                            :item-text="layoutSession.typeIntegrationValue === 'SHIPPING' ? 'label' : 'name'"
                            :item-value="layoutSession.typeIntegrationValue === 'SHIPPING' ? 'value' : 'name'"
                            placeholder="Informe"
                            color="textPrimary"
                            class="pt-4"
                            dense
                            outlined
                            :disabled="isVisualization || !['', null, undefined].some(el => el === item.value)"
                            @change="processField(item, 'original')"
                          />
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                        xs="12">
                        <div
                          class="d-flex flex-row align-center"
                          :id="
                            ['', null, undefined].some(el => el === item.columnValue)
                            ? 'fromToAdvanceActiveField'
                            : ''">
                          <label
                            class="px-3 text-subtitle-1">
                            Valor Fixo:
                          </label>
                          <v-text-field
                            v-model="item.value"
                            class="pt-4"
                            dense
                            outlined
                            :disabled="isVisualization || !['', null, undefined].some(el => el === item.columnValue)"
                            @input="processField(item, 'fixed')"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.isAlphanumeric`]="{ item }">
                    <v-row class="pt-2">
                      <v-autocomplete
                        :items="alphanumericItem"
                        v-model="item.isAlphanumeric"
                        class="pt-4"
                        dense
                        outlined
                        :disabled="isVisualization || !['', null, undefined].some(el => el === item.columnValue) "
                        @change="item.isAlphanumeric === true ? item.columnValue = null : false"
                      />
                    </v-row>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small :disabled="isVisualization" color="error" @click="removeParameter(item)">
                      <v-icon color="#fff" style="font-size: 21px">
                        fas fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
                    <v-icon
                      :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                      @click.stop="expand(!isExpanded)" @click="!isExpanded ? captureParentRow(item) : null"
                    >
                      $expand
                    </v-icon>
                  </template>

                  <template v-slot:expanded-item="{ item: itemParent }">
                    <td :colspan="conditionHeaders.length" class="mx-0 px-0">
                      <v-row class="pa-0 ma-0">
                        <v-col class="pa-0" cols="12">
                          <v-data-table
                            item-key="order"
                            dense
                            :items="itemParent.conditions"
                            :headers="conditionHeaders"
                            fixed-header
                            hide-default-footer
                            hide-default-header
                          >
                            <template v-slot:[`header`]="{ props }">
                              <thead>
                                <tr>
                                  <th style="background-color: #d9d9d9 !important; text-align: start;" v-for="(header) in props.headers" :key="header.text">
                                    <label class="label primary--text">
                                      {{ header.text }}
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                            </template>
                            <template v-slot:[`item.conditionType`]="{ item }">
                              <v-row class="py-2">
                                <v-autocomplete
                                  :items="conditionTypes"
                                  :value="item.parameterConditionType.value"
                                  class="pt-4"
                                  dense
                                  outlined
                                  disabled
                                />
                              </v-row>
                            </template>
                            <template v-slot:[`item.column`]="{ item }">
                              <v-row class="py-2">
                                <v-autocomplete
                                  :items="itemParent.dataSourceColumnsAlt"
                                  v-model="item.parameterCondition.value"
                                  item-value="value"
                                  item-text="label"
                                  class="pt-4"
                                  dense
                                  outlined
                                  :disabled="isVisualization"
                                  required
                                  @input="updateSelection(itemParent, itemParent.dataSourceColumnsAlt)"
                                />
                              </v-row>
                            </template>
                            <template v-slot:[`item.operator`]="{ item }">
                              <v-row class="py-2">
                                <v-autocomplete
                                  :items="operators"
                                  v-model="item.parameterOperator.value"
                                  item-text="label"
                                  item-value="value"
                                  class="pt-4"
                                  dense
                                  outlined
                                  :disabled="isVisualization"
                                  required
                                />
                              </v-row>
                            </template>
                            <template v-slot:[`item.conditionValue`]="{ item }">
                              <v-row class="py-2">
                                <v-text-field
                                  v-model.trim="item.parameterValue.value"
                                  class="pt-4"
                                  dense
                                  outlined
                                  :disabled="isVisualization"
                                  required
                                />
                              </v-row>
                            </template>
                            <template v-slot:[`item.isAlphanumeric`]="{ item }">
                              <v-row class="py-2">
                                <v-autocomplete
                                  :items="alphanumericItem"
                                  v-model="item.parameterValue.isAlphanumeric"
                                  class="pt-4"
                                  dense
                                  outlined
                                  :disabled="isVisualization"
                                  :rules="item.parameterValue.value === '' && !item.parameterValue.isAlphanumeric ? ['Campo inválido'] : []"
                                />
                              </v-row>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <div class="d-flex justify-center">
                                <v-btn class="mr-2" small :disabled="isVisualization" color="primary" @click="addCondition(itemParent)">
                                  <v-icon color="#fff" style="font-size: 21px">
                                    fas fa-plus
                                  </v-icon>
                                </v-btn>
                                <v-btn small :disabled="isVisualization || item.parameterConditionType.value === 'if'" color="error" @click="removeCondition(item, itemParent, itemParent.dataSourceColumnsAlt)">
                                  <v-icon color="#fff" style="font-size: 21px">
                                    fas fa-trash-alt
                                  </v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-card-subtitle style="width: 100%; padding-bottom: 100px;">
            <v-row class="pa-0 ma-0">
              <v-col class="px-0 py-0 mt-10" cols="12">
                <label class="label">Se nenhum critério for atendido</label>

                <template>
                  <v-data-table
                    class="mt-1"
                    dense
                    loading-text="Carregando..."
                    :headers="headersElse"
                    :items="parameterElse"
                    :items-per-page="-1"
                    item-key="order"
                    hide-default-footer
                  >
                    <template v-slot:no-data>
                      <span>Não há dados</span>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                      <v-row
                        class="pt-2"
                        no-gutters>
                        <v-col
                          cols="12"
                          lg="6"
                          md="6"
                          sm="12"
                          xs="12">
                          <div
                            class="d-flex flex-row align-center"
                            :id="['', null, undefined].some(el => el === item.value)
                              ? 'fromToAdvanceActiveField'
                              : ''">
                            <label
                              class="text-subtitle-1 pr-3">
                              Valor do Original:
                            </label>
                            <v-autocomplete
                              v-model="item.columnValue"
                              :items="layoutSession.typeIntegrationValue === 'SHIPPING' ? dataSourceColumns : layout.columns"
                              :item-text="layoutSession.typeIntegrationValue === 'SHIPPING' ? 'label' : 'name'"
                              :item-value="layoutSession.typeIntegrationValue === 'SHIPPING' ? 'value' : 'name'"
                              placeholder="Informe"
                              color="textPrimary"
                              class="pt-4"
                              dense
                              outlined
                              :disabled="isVisualization || !['', null, undefined].some(el => el === item.value)"
                              @change="processField(item, 'original')"
                            />
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                          md="6"
                          sm="12"
                          xs="12">
                          <div
                            class="d-flex flex-row align-center"
                            :id="['', null, undefined].some(el => el === item.columnValue)
                              ? 'fromToAdvanceActiveField'
                              : ''">
                            <label
                              class="px-3 text-subtitle-1">
                              Valor Fixo:
                            </label>
                            <v-text-field
                              v-model="item.value"
                              class="pt-4"
                              dense
                              outlined
                              :disabled="isVisualization || !['', null, undefined].some(el => el === item.columnValue)"
                              @input="processField(item, 'fixed')"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.isAlphanumeric`]="{ item }">
                      <v-row class="pt-2">
                        <v-autocomplete
                          :items="alphanumericItem"
                          v-model="item.isAlphanumeric"
                          class="pt-4"
                          dense
                          outlined
                          @change="item.isAlphanumeric === true ? item.columnValue = null : false"
                          :disabled="isVisualization || !['', null, undefined].some(el => el === item.columnValue)"
                        />
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-card-actions style="position: absolute; bottom: 0; left: 0; width: 100%; padding: 16px; background-color: #fff; box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);">
            <v-row justify="end" class="py-2">
              <v-btn color="primary" outlined class="px-7 mr-5" @click="close()"> {{ isVisualization ? 'Ok' : 'Cancelar' }} </v-btn>
              <v-btn v-if="!isVisualization" color="primary" type="submit" class="mr-3 px-10" @click="onClickSave()"> Salvar </v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </div>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import Formatters from '@/shared/formatters/formatters';
import lodash from 'lodash';
import { mapGetters } from 'vuex';

export default ({
  name: 'transformationsFromToAdvancedModal',

  components: { SnackbarCustomize },

  data: () => ({
    transformationsFromToAdvancedModal: false,
    isVisualization: false,
    orderParameter: 1,
    orderCondition: 1,

    columnName: '-',

    transformation: {},
    parentRow: {},
    layoutSession: {},

    parametersFromToAdvanced: [],
    arrayAux: [],
    dataSourceColumnsAlt: [],
    expanded: [],
    operators: [
      {
        label: 'Igual',
        value: '=',
      },
      {
        label: 'Maior ou igual',
        value: '>=',
      },
      {
        label: 'Menor ou igual',
        value: '<=',
      },
      {
        label: 'Menor',
        value: '<',
      },
      {
        label: 'Maior',
        value: '>',
      },
      {
        label: 'Diferente',
        value: '<>',
      },
    ],
    parameterElse: [
      {
        isAlphanumeric: true,
        isField: false,
        name: 'ELSE',
        order: 1,
        value: '',
      },
    ],
    conditionTypes: [
      {
        text: 'SE',
        value: 'if',
      },
      {
        text: 'E',
        value: 'and',
      },
    ],
    alphanumericItem: [
      { text: 'SIM', value: true },
      { text: 'NÃO', value: false },
    ],
    headers: [
      {
        text: 'Preencher Com',
        value: 'value',
        sortable: false,
        width: '80%',
      },
      {
        text: 'Alfanumérico',
        value: 'isAlphanumeric',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        align: 'start',
      },
      {
        text: '',
        value: 'data-table-expand',
        sortable: false,
      },
    ],
    headersElse: [
      {
        text: 'Preencher com',
        value: 'value',
        sortable: false,
        width: '80%',
      },
      {
        text: 'Alfanumérico',
        value: 'isAlphanumeric',
        sortable: false,
      },
    ],
    conditionHeaders: [
      {
        text: 'Condição',
        value: 'conditionType',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Coluna',
        value: 'column',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Operador',
        value: 'operator',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Valor',
        value: 'conditionValue',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Alfanumérico',
        value: 'isAlphanumeric',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ],
  }),

  props: {
    propsTransformationParameter: Object,
    dataSourceColumns: Array,
    layout: Object,
  },

  watch: {
    dataSourceColumns: {
      handler(val) {
        if (val && val.length > 0) {
          this.validateDataSource(val);
        }
      },
    },
  },

  mounted() {
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
    this.validateDataSource();
  },

  methods: {
    processField(item, type) {
      if (type === 'fixed') {
        item.isAlphanumeric = true;
        item.isField = false;
      } else {
        item.isAlphanumeric = false;
        item.isField = true;
      }
    },
    validateDataSource(dataSource) {
      if (this.layoutData) {
        // this.layoutSession = JSON.parse(sessionStorage.getItem('layout'));
        this.layoutSession = JSON.parse(JSON.stringify(this.layoutData));
        if (this.layoutSession.typeIntegrationValue === 'RECEIVEMENT') {
          this.layoutSession.columns.forEach((column) => {
            this.dataSourceColumnsAlt.push({
              label: column.name,
              value: column.name,
              disabled: false,
            });
          });
        } else {
          this.dataSourceColumnsAlt = dataSource;
          this.formaterLabelDataSource();
        }
      }
    },
    formaterLabelDataSource() {
      if (this.dataSourceColumnsAlt && this.dataSourceColumnsAlt.length > 0) {
        this.dataSourceColumnsAlt = this.dataSourceColumnsAlt.filter((element) => {
          element.label = this.formatter.formatToTitleCase(element.label);
          return true;
        });
      }
    },
    open(item) {
      this.columnName = item.name;
      this.transformationsFromToAdvancedModal = true;
      this.transformation = this.propsTransformationParameter;

      if (this.transformation && this.transformation.parameters.length > 0) {
        this.mountedParametersFromToAdvanced();
      }
    },
    close() {
      this.clearParameters();
      this.addDefaultElseParameter();
      this.reorderParameters();
      this.hideTransformationsModal();
    },
    reorderParameters() {
      if (this.transformation.parameters.length > 0) {
        this.transformation.parameters.forEach(parameter => {
          if (!['', null, undefined].some(el => el === parameter.columnValue)) {
            parameter.value = parameter.columnValue;
            delete parameter.columnValue;
          }
        })

        this.$emit('on-confirm', this.transformation);
      }
    },
    clearParameters() {
      this.parametersFromToAdvanced = [];
      this.parameterElse = [];
    },
    addDefaultElseParameter() {
      this.parameterElse.push({
        isAlphanumeric: true,
        isField: false,
        name: 'ELSE',
        order: 1,
        value: '',
      });
    },
    hideTransformationsModal() {
      this.transformationsFromToAdvancedModal = false;
    },
    addParameter() {
      const parameters = {
        isAlphanumeric: true,
        value: '',
        order: this.orderParameter,
        name: 'THEN',
        dataSourceColumnsAlt: lodash.cloneDeep(this.dataSourceColumnsAlt),
        conditions: [
          {
            parameterConditionType: this.createParameter(false, false, 'CONDITIONTYPE', 1, 'if'),
            parameterCondition: this.createParameter(false, true, 'COLUMN', 1, ''),
            parameterOperator: this.createParameter(false, false, 'OPERATOR', 1, ''),
            parameterValue: this.createParameter(true, false, 'VALUE', 1, ''),
          },
        ],
      };

      this.orderParameter += 1;

      this.parametersFromToAdvanced.push(parameters);

      this.expanded = this.parametersFromToAdvanced;

      this.parentRow = parameters;
    },
    createParameter(isAlphanumeric, isField, name, order, value) {
      return {
        isAlphanumeric,
        isField,
        name,
        order,
        value,
      };
    },
    captureParentRow(item) {
      this.parentRow = {};
      this.parentRow = item;
    },
    addCondition(item) {
      const index = this.parametersFromToAdvanced.findIndex((element) => element.order === item.order);

      if (index !== -1) {
        this.parametersFromToAdvanced[index].conditions.push(
          {
            parameterConditionType: this.createParameter(false, false, 'CONDITIONTYPE', 1, 'and'),
            parameterCondition: this.createParameter(false, true, 'COLUMN', 1, ''),
            parameterOperator: this.createParameter(false, false, 'OPERATOR', 1, ''),
            parameterValue: this.createParameter(true, false, 'VALUE', 1, ''),
          },
        );
      }
    },
    removeParameter(item) {
      const indexParameter = this.parametersFromToAdvanced.indexOf(item);
      this.parametersFromToAdvanced.splice(indexParameter, 1);
      this.updateSelection();
    },
    removeCondition(item, itemParent, dataSourceParent) {
      const parentIndex = this.parametersFromToAdvanced.findIndex((element) => element.order === itemParent.order);

      if (parentIndex !== -1) {
        const conditionIndex = this.parametersFromToAdvanced[parentIndex].conditions.indexOf(item);

        if (conditionIndex !== -1) {
          this.parametersFromToAdvanced[parentIndex].conditions.splice(conditionIndex, 1);
        }
      }
      this.updateSelection(itemParent, dataSourceParent);
    },
    getThStyle(index) {
      if (index === 0) {
        return 'width: 80%; background-color: #d9d9d9 !important; text-align: start;';
      }
      return 'background-color: #d9d9d9 !important; text-align: start;';
    },
    mountedParametersFromToAdvanced() {
      const { parameters } = this.transformation;
      let currentConditions = [];
      const parametersAux = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < parameters.length; i++) {
        const item = parameters[i];
        if (item.name !== 'THEN' && item.name !== 'ELSE') {
          currentConditions.push(item);
        }
        if (item.name === 'THEN') {
          if (item.value && item.isAlphanumeric === false && item.isField === true) {
            item.columnValue = item.value;
            item.value = '';
          }
          parametersAux.push(
            {
              ...item,
              dataSourceColumnsAlt: lodash.cloneDeep(this.dataSourceColumnsAlt),
              conditions: currentConditions,
            },
          );
          currentConditions = [];
        }
        if (item.name === 'SENAO' || item.name === 'ELSE') {
          this.parameterElse = [
            {
              isAlphanumeric: item.isAlphanumeric,
              isField: item.isField,
              name: item.name,
              order: item.order,
              value: item.isAlphanumeric === false && item.isField === true ? '' : item.value,
              columnValue: item.isAlphanumeric === false && item.isField === true ? item.value : null
            },
          ];
        }
      }

      let arrayAuxiliar = [];
      parametersAux.forEach((item) => {
        const conditionsArray = item.conditions;
        const parameterGroup = [];

        for (let i = 0; i < conditionsArray.length; i += 3) {
          if (i + 2 < conditionsArray.length) {
            const parameterCondition = conditionsArray[i];
            const parameterOperator = conditionsArray[i + 1];
            const parameterValue = conditionsArray[i + 2];

            const parameterSet = {
              parameterCondition,
              parameterOperator,
              parameterValue,
            };

            const conditionTypeValue = i === 0 ? 'if' : 'and';
            parameterSet.parameterConditionType = this.createParameter(false, false, 'CONDITIONTYPE', 1, conditionTypeValue);

            parameterGroup.push(parameterSet);
          }
        }

        arrayAuxiliar.push(...parameterGroup);
        item.conditions = arrayAuxiliar;
        arrayAuxiliar = [];
      });

      this.parametersFromToAdvanced = parametersAux;

      this.parametersFromToAdvanced.forEach((item) => {
        this.updateSelection(item, item.dataSourceColumnsAlt);
      });
      this.defineExpandedParameter()
    },
    defineExpandedParameter() {
      if(this.parametersFromToAdvanced.length > 0) {
        this.expanded = this.parametersFromToAdvanced;
        if (this.expanded.length > 0) {
          this.parentRow = this.expanded[0];
        }
      }
    },
    onClickSave() {
      if (this.validateFields()) {
        this.adjustFromToAdvancedInLayout();
        this.resetOrder();
        this.$emit('on-confirm', this.transformation);
        this.close();
      }
    },
    validateFields() {
      if (this.parametersFromToAdvanced && this.parametersFromToAdvanced.length === 0) {
        this.$refs.SnackbarCustomize.open('error', 'Não há parâmetros suficiente para prosseguir');
        return false;
      }

      // if (this.parameterElse[0].value === '' && this.parameterElse[0].isAlphanumeric) {
      //   this.$refs.SnackbarCustomize.open('error', 'Para valores vazios, os mesmos devem ser alfanuméricos e definidos no campo Valor Fixo');
      //   return false;
      // }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.parametersFromToAdvanced.length; i++) {
        const item = this.parametersFromToAdvanced[i];
        // if (item.value === '' && ['', null, undefined].some(el => el === item.columnValue)) {
        //   this.$refs.SnackbarCustomize.open('error', 'Verifique os parâmetros para prosseguir');
        //   return false;
        // }

        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < item.conditions.length; j++) {
          const condition = item.conditions[j];
          if (condition.parameterCondition.value === '') {
            this.$refs.SnackbarCustomize.open('error', 'Você precisa adicionar uma coluna para prosseguir');
            return false;
          }
          if (condition.parameterOperator.value === '') {
            this.$refs.SnackbarCustomize.open('error', 'Você precisa adicionar um operador lógico para prosseguir');
            return false;
          }
          if (condition.parameterValue.value === '' && !condition.parameterValue.isAlphanumeric) {
            this.$refs.SnackbarCustomize.open('error', 'Para condições com valores vazios, os mesmos devem ser alfanuméricos');
            return false;
          }
        }
      }

      return true;
    },
    resetOrder() {
      let orderParameter = 1;
      this.arrayAux.forEach((parameter) => {
        parameter.order = orderParameter;
        orderParameter += 1;
      });
      this.transformation.parameters = this.arrayAux;
    },
    adjustFromToAdvancedInLayout() {
      this.arrayAux = [];

      this.extractConditionsFromParameters();
      this.extractParametersFromElse();
    },
    extractConditionsFromParameters() {
      this.parametersFromToAdvanced.forEach((parameter) => {
        if (!['', null, undefined].some(el => el === parameter.columnValue)) {
          parameter.value = parameter.columnValue;
          delete parameter.columnValue;
        }
        parameter.conditions.forEach((condition) => {
          const { parameterCondition, parameterOperator, parameterValue } = condition;
          this.arrayAux.push({ ...parameterCondition });
          this.arrayAux.push({ ...parameterOperator });
          this.arrayAux.push({ ...parameterValue });
        });

        this.extractParameters(parameter);
      });
    },
    extractParameters(parameter) {
      const { isAlphanumeric, name, order, value, isField } = parameter;
      this.arrayAux.push({
        isAlphanumeric, name, order, value, isField,
      });
    },
    extractParametersFromElse() {
      this.extractFixedParameters();
    },
    extractFixedParameters() {
      if (!['', null, undefined].some(el => el === this.parameterElse[0].columnValue)) {
        this.parameterElse[0].value = this.parameterElse[0].columnValue;
        delete this.parameterElse[0].columnValue;
      }

      const {
        isAlphanumeric, name, order, value, isField,
      } = this.parameterElse[0];

      this.arrayAux.push({
        isAlphanumeric, name, order, value, isField,
      });
    },
    updateSelection(itemParent, dataSourceParent) {
      if (dataSourceParent && Array.isArray(dataSourceParent)) {
        dataSourceParent.forEach((dataSource) => {
          const isValueUsed = itemParent.conditions.some((condition) => condition.parameterCondition.value === dataSource.value);
          dataSource.disabled = isValueUsed;
        });
      }

      this.$forceUpdate();
    },
  },

  computed: {
    ...mapGetters({
      layoutData: 'layout-import/layout'
    })
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
  },
});
</script>
<style>
#fromToAdvanceActiveField fieldset {
  color: #3B495B !important;
  border-width: 2px;
}
</style>