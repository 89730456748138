<template>
  <v-container>
    <v-dialog v-model="transformationsModal" persistent max-width="900">
        <v-card class="pa-5 modalLayoutTransformationsTopCenter">
          <label class="primary--text font-weight-bold"> Configuração da Transformação: <label class="title">{{ transformation && transformation.name ? transformation.name : '-' }}</label></label></br>
          <label class="primary--text font-weight-bold"> Coluna Comparada: <label class="title">{{ columnName }}</label></label>
          <v-form v-model="formParameters" ref="formParameters">
            <template v-if="transformation && transformation.parameters && transformation.parameters.length > 0">
              <v-card-subtitle class="pa-0 ma-0" v-for="(parameter, indexParameters) in transformation.parameters" :key="indexParameters">
                <template v-if="!parameter.isField">
                  <v-checkbox label="Alfanumérico" :disabled="isVisualization || observeTransformationToDisable(transformation, parameter)" v-model="parameter.isAlphanumeric" />
                  <span class="font-weight-bold label">{{ parameter.name }}</span>
                    <v-row class="mx-auto pb-0">
                      <v-col cols="12" class="pl-0 mt-4">
                        <v-text-field
                          dense
                          solo
                          label="Valor"
                          v-model="parameter.value"
                          :rules="(transformation.template === 'replace_all' || transformation.template === 'regex') && parameter.order === 3 ? [] : [rule.required]"
                          validate-on-blur
                          :disabled="isVisualization"
                        />
                      </v-col>
                    </v-row>
                  <v-divider v-if="transformation.parameters && transformation.parameters.length > 2" />
                </template>
              </v-card-subtitle>
            </template>
          </v-form>
          <v-card-actions>
            <v-row justify="end" class="mt-2">
              <v-btn color="primary" outlined class="px-7 mr-5" @click="close()"> {{ isVisualization ? 'Ok' : 'Cancelar' }} </v-btn>
              <v-btn v-if="!isVisualization" color="primary" class="mr-3 px-10" @click="onClickSave()"> Salvar </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';

export default ({
  name: 'transformationsModal',

  data: () => ({
    transformationsModal: false,
    formParameters: false,
    isVisualization: false,
    transformation: {},
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    columnName: '',
  }),

  props: { propsTransformationParameter: Object },

  mounted() {
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
  },

  methods: {
    open(item) {
      this.columnName = item.name;
      this.transformationsModal = true;
      this.transformation = this.propsTransformationParameter;
      if (this.transformation && this.transformation.template === 'regex') {
        this.transformation.parameters.sort((a, b) => this.sortByParam(a, b));
      }
    },
    sortByParam(a, b) {
      if (a.isField && !b.isField) {
        this.transformation.parameters[1].order = 1;
        this.transformation.parameters[0].order = 2;
        return -1;
      }
      return 0;
    },
    close() {
      this.transformationsModal = false;
      this.$emit('on-cancel', this.transformation);
    },
    async incrementParameterInTransformations() {
      if (this.transformation.template === 'calcula_data' && this.transformation.parameters && this.transformation.parameters.length < 4) {
        this.transformation.parameters.unshift({
          isAlphanumeric: false,
          isField: true,
          order: 1,
          name: this.columnName,
          value: this.columnName,
        });
        this.transformation.parameters = this.transformation.parameters.map((parameter) => {
          if (!parameter.isField) {
            return {
              ...parameter,
              isField: false,
            };
          }
          return { ...parameter };
        });
      } else if(this.transformation.template !== 'valor_fixo') {

        const transformationSelected = this.transformation.parameters.find((parameter) => parameter.order === 1);
        transformationSelected.isAlphanumeric = false;
        transformationSelected.isField = true;
        transformationSelected.order = 1;
        transformationSelected.name = this.columnName;
        transformationSelected.value = this.columnName;
      }
    },
    async onClickSave() {
      if (this.$refs.formParameters.validate()) {
        await this.incrementParameterInTransformations();
        this.$emit('on-confirm', this.transformation);
        this.transformationsModal = false;
      } else {
        this.customizeSnackbarMessage('error', 'Preencha os campos para prosseguir');
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    observeTransformationToDisable(transformation, parameter) {
      if (transformation.template === 'replace_all' || transformation.template === 'regex') {
        // eslint-disable-next-line no-param-reassign
        parameter.isAlphanumeric = true;
        return true;
      }
      return false;
    },
  },

  created() {
    this.rule = new Rules();
  },
});
</script>
