<template>
  <div>
    <v-dialog
      v-model="modalIsOpened"
      persistent
      max-width="1200">
      <v-card
        class="pa-5 modalLayoutTransformationsTopCenterFromTo">
        <div
          :style="cardStyle">
          <v-card-title
            :style="cardTitleStyle">
            <v-row
              no-gutters>
              <v-col
                cols="12">
                <label
                  class="primary--text font-weight-bold">
                  Configuração da Transformação:
                  <label
                    class="title">
                    Junção de Colunas
                  </label>
                </label>
              </v-col>
              <v-col
                cols="12">
                <label
                  class="primary--text font-weight-bold">
                  Coluna Comparada:
                  <label
                    class="title">
                    {{ columnName }}
                  </label>
                </label>
              </v-col>
            </v-row>
          </v-card-title>

          <v-container
            fluid>
            <v-row
              no-gutters>
              <v-col
                cols="12">
                <v-row
                  no-gutters
                  class="d-flex align-center">
                  <v-tooltip
                    bottom>
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="addColumn">
                        <v-icon>
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> Adicionar Coluna </span>
                  </v-tooltip>
                  <v-radio-group
                    v-model="columnTypeSelected"
                    class="pl-4"
                    row>
                    <v-radio
                      v-for="(column, index) in [
                        {id: 'selectable', label: 'Campo selecionável'},
                        {id: 'fixed', label: 'Campo fixo'}
                      ]"
                      :key="index"
                      :label="column.label"
                      :value="column.id"
                    />
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="mt-3">
              <v-col
                cols="10">
                <v-form
                  ref="TransformationConcatColumnForm">
                  <v-row
                    no-gutters>
                    <v-col
                      v-for="(column, index) in columns"
                      :cols="column.type === 'selectable' ? 2 : 1"
                      :key="index"
                      class="px-1">
                      <v-speed-dial
                        :open-on-hover="true"
                        transition="slide-y-reverse-transition">
                        <template
                          v-slot:activator>
                          <template
                            v-if="column.type === 'selectable'">
                            <v-autocomplete
                              v-model="column.value"
                              :items="dataSourceColumnsAlt"
                              item-text="label"
                              item-value="value"
                              color="textPrimary"
                              class="text-subtitle-1 mb-2"
                              dense
                              :outlined="messageError != ''"
                              solo
                              validate-on-blur
                              :rules="[rule.required]"
                              hide-details
                            />
                          </template>
                          <template
                            v-else>
                            <v-text-field
                              dense
                              solo
                              maxlength="1"
                              :class="{
                                'transformation-concat-input-center text-h6 textPrimary mb-2': true,
                              }"
                              :outlined="messageError != ''"
                              v-model="column.value"
                              :rules="[rule.required]"
                              hide-details
                            />
                          </template>
                        </template>
                        <v-tooltip
                          bottom>
                          <template
                            v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              dark
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              @click="removeColumn(index)">
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Remover campo</span>
                        </v-tooltip>
                      </v-speed-dial>
                    </v-col>
                  </v-row>
                  <p
                    v-if="messageError !== ''"
                    class="error--text text-body-2 pl-2">
                    {{ messageError }}
                  </p>
                </v-form>
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-end">
                <v-btn
                  v-if="columns.length > 0"
                  small
                  color="error"
                  @click="removeRow()">
                  <v-icon
                    color="#fff"
                    style="font-size: 21px">
                    fas fa-trash-alt
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-card-actions>
          <v-row class="mt-6">
            <v-spacer />
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                class="mr-3 pa-6"
                color="#3B495B"
                outlined
                block
                @click="close()">
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                class="mr-6 pa-6"
                color="#3B495B"
                block
                style="color: white"
                @click="save()">
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ExclusionDynamicModal
      :show="showDynamicDialog"
      @close="closeDynamicDialog"
      @confirm="confirmRowRemoval">
      <template slot="header"> {{ titleDynamicDialog }} </template>
      <template slot="content">
        {{ contentDynamicDialog }}
      </template>
    </ExclusionDynamicModal>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </div>
</template>

<script>
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import Rules from '@/shared/validators/formRules';

export default ({
  name: 'transformationsConcatColumnModal',

  components: {
    ExclusionDynamicModal,
    SnackbarCustomize
  },

  data: () => ({
    showDynamicDialog: false,

    columnName: null,
    modalIsOpened: null,
    titleDynamicDialog: null,
    contentDynamicDialog: null,
    columnTypeSelected: null,
    rule: null,

    messageError: '',

    transformation: {},
    columns: [],
    dataSourceColumnsAlt: [],

    cardStyle: `
      max-height: 800px;
      overflow-y: auto;
      overflow-x: hidden;`,
    cardTitleStyle: `
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);`,
  }),

  props: {
    dataSourceColumns: Array,
    propsTransformationParameter: Object
  },

  watch: {
    dataSourceColumns: {
      handler(val) {
        if (val && val.length > 0) {
          this.validateDataSource(val);
        }
      },
    },
  },

  methods: {
    validateDataSource(dataSource) {
      if (sessionStorage.getItem('layout')) {
        this.layoutSession = JSON.parse(sessionStorage.getItem('layout'));
        if (this.layoutSession.typeIntegrationValue === 'RECEIVEMENT') {
          this.layoutSession.columns.forEach((column) => {
            this.dataSourceColumnsAlt.push({
              label: column.name,
              value: column.name,
              disabled: false,
            });
          });
        } else {
          this.dataSourceColumnsAlt = dataSource;
        }
      }
    },
    // open Dialog
    open(item) {
      this.columns = [];
      this.messageError = '';
      this.columnName = item.name;
      this.transformation = this.propsTransformationParameter;
      this.setColumns();
      this.modalIsOpened = true;
    },
    // close Dialog
    close() {
      this.modalIsOpened = false;
    },
    // save new configuration
    save() {
      if (!this.columnsValidation()) return;
      this.processColumns();
      this.$emit('on-confirm', this.transformation)
      this.close();
    },
    // set column params
    setColumns() {
      this.transformation.parameters.forEach(el => {
        if (el.isField === true && el.isAlphanumeric === false) {
          el.type = 'selectable';
          this.columns.push(el);
        } else if (el.value !== '') {
          el.type = 'fixed';
          this.columns.push(el);
        }
      })
    },
    // valid all columns
    columnsValidation() {
      this.messageError = '';
      if (this.columns.length < 2) {
        this.$refs.SnackbarCustomize.open('error', 'Defina ao menos 2 campos.');
        return false
      }
      if (this.columns.length > 10) {
        this.$refs.SnackbarCustomize.open('error', 'Máximo de 10 campos permitidos.');
        return false
      }
      if (!this.$refs.TransformationConcatColumnForm.validate()) {
        this.messageError = "Preenchimento obrigatório"
        return false;
      }
      return true;
    },
    // create a list of columns with 10 indices
    processColumns() {
      while (this.columns.length < 10) {
        this.columns.push({
          type: "fixed",
          value: "",
          isAlphanumeric: true,
          isField: false,
        });
      }
      this.columns.map((column ,index) => {
        column.order = index+1;
      })
      this.transformation.parameters = this.columns;
    },
    // close custom dialog
    closeDynamicDialog() {
      this.showDynamicDialog = false;
    },
    // confirm action in custom dialog
    confirmRowRemoval() {
      this.columns = [];
      this.messageError = '';
      this.closeDynamicDialog();
    },
    // add column
    addColumn() {
      if (this.columnTypeSelected === null) {
        this.$refs.SnackbarCustomize.open('success', 'Por favor, selecione um tipo de campo');
        return
      }

      if (this.columnTypeSelected === "selectable") {
        this.columns.push(
          {
            type: "selectable",
            value: null,
            isAlphanumeric: false,
            isField: true,
          }
        )
      } else {
        this.columns.push(
          {
            type: "fixed",
            value: "",
            isAlphanumeric: true,
            isField: false,
          }
        )
      }
    },
    // remove row
    removeRow() {
      this.titleDynamicDialog = 'Excluir linha';
      this.contentDynamicDialog = 'Tem certeza que deseja remover toda a linha?';
      this.typeDynamicAction = 'DELETE';
      this.showDynamicDialog = true;
    },
    // remove column
    removeColumn(indexColumn) {
      this.columns.splice(indexColumn, 1);
    },
  },

  mounted() {
    this.rule = new Rules();
  },
});
</script>

<style scoped>
  .transformation-concat-input-center >>> input {
    text-align: center !important;
  }
</style>