<template>
  <v-container>
    <v-dialog v-model="transformationsFromToModal" persistent max-width="1200">
      <v-card class="pa-5 modalLayoutTransformationsTopCenterFromTo">
        <div style="overflow-y: auto; overflow-x: hidden;">
          <v-card-title class="white--text" style="position: sticky; top: 0; background-color: #fff; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);">
            <v-row>
              <v-col cols="12">
                <label class="primary--text font-weight-bold"> Configuração da Transformação: <label class="title">{{ transformation.name }}</label></label><br>
                <label class="primary--text font-weight-bold"> Coluna Comparada: <label class="title">{{ columnName }}</label></label>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-subtitle class="pa-0 ma-0 mb-10" style="height: 513px; overflow-y: auto;">
            <template>
              <v-form v-model="formFromTo" ref="formFromTo">
                <v-row class="pa-0 ma-0">
                  <v-col class="px-0 py-0 mt-5" cols="12">
                    <v-data-table
                      class="elevation-1 px-0"
                      :headers="headers"
                      :items="parametersFromTo"
                      :items-per-page="-1"
                      loading-text="Carregando..."
                      dense
                    >
                      <template v-slot:no-data>
                        <span>Não há dados</span>
                      </template>
                      <template v-slot:top>
                        <v-toolbar flat short style="background-color: transparent !important">
                          <v-col align="end">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="addParameter()"
                                  v-if="!isVisualization"
                                >
                                  <v-icon>
                                    fas fa-plus
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Adicionar Coluna</span>
                            </v-tooltip>
                          </v-col>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.isAlphanumeric`]="{ item }">
                        <v-row class="justify-center pl-3">
                          <v-select
                            :items="alphanumericItem"
                            v-model="item.parameterPARA.isAlphanumeric"
                            class="pt-4"
                            dense
                            solo
                            :disabled="isVisualization"
                          />
                        </v-row>
                      </template>
                      <template v-slot:[`item.DE`]="{ item }">
                        <v-text-field
                          v-model="item.parameterDE.value"
                          class="pt-4"
                          dense
                          solo
                          :rules="[rule.required]"
                          :disabled="isVisualization"
                        />
                      </template>
                      <template  v-slot:[`item.PARA`]="{ item }">
                        <v-text-field
                          v-model="item.parameterPARA.value"
                          class="pt-4"
                          dense
                          solo
                          :rules="[rule.required]"
                          :disabled="isVisualization"
                        />
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn :disabled="isVisualization" color="error" @click="removeParameter(item)">
                          <v-icon color="#fff" style="font-size: 21px">
                            fas fa-trash-alt
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                    <v-col class="mt-5" cols="12 d-flex justify-center"><h1 class="h1">SENÃO</h1></v-col>

                    <v-col cols="12 py-0" :class="selectedOption === 'original' ? 'mb-10' : ''">
                      <RadioGroupSelected :selectedOption="selectedOption" @handleChangedRadioOption="handleChangedRadioOption" />
                    </v-col>

                    <template v-if="selectedOption === 'fixed'">
                      <v-col cols="12" md="3" class="py-0">
                        <v-checkbox
                          v-model="parameterElse.isAlphanumeric"
                          color="textPrimary"
                          label="Alfanumérico"
                          :disabled="isVisualization"
                        />
                        <label class="label">Retorna:</label>
                        <v-text-field
                          v-model="parameterElse.value"
                          color="textPrimary"
                          class="mt-2"
                          placeholder="Informe o retorno"
                          dense
                          outlined
                          :disabled="isVisualization"
                        />
                      </v-col>
                    </template>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </v-card-subtitle>

          <v-card-actions ref="scrollBottom" style="position: absolute; bottom: 0; left: 0; width: 100%; padding: 16px; background-color: #fff; box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);">
            <v-row justify="end" class="py-2">
              <v-btn color="primary" outlined class="px-7 mr-5" @click="close()"> {{ isVisualization ? 'Ok' : 'Cancelar' }} </v-btn>
              <v-btn v-if="!isVisualization" color="primary" class="mr-3 px-10" @click="onClickSave()"> Salvar </v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import RadioGroupSelected from '@/components/Layout/Transformations/Common/RadioGroupSelected.vue';

export default ({
  name: 'transformationsFromToModal',

  components: { SnackbarCustomize, RadioGroupSelected },

  data: () => ({
    transformationsFromToModal: false,
    isVisualization: false,
    formFromTo: false,
    countIndexParameter: 1,

    selectedOption: 'original',
    columnName: '',

    transformation: {},
    layoutSession: {},
    parameterElse: {
      isAlphanumeric: false,
      isField: true,
      name: 'SENAO',
      order: 1,
      value: '',
    },

    parameterDE: [],
    parameterPARA: [],
    parametersFromTo: [],
    alphanumericItem: [
      {
        text: 'Sim',
        value: true,
        align: 'center',
      },
      {
        text: 'Não',
        value: false,
        align: 'center',
      },
    ],
    headers: [
      {
        text: 'Alfanumérico',
        value: 'isAlphanumeric',
        align: 'center',
        width: '15%',
        sortable: false,
      },
      {
        text: 'Se for',
        value: 'DE',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Retorna',
        value: 'PARA',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
  }),

  props: { propsTransformationParameter: Object },

  mounted() {
    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
    if (sessionStorage.getItem('layout')) {
      this.layoutSession = JSON.parse(sessionStorage.getItem('layout'));
    }
  },

  methods: {
    open(item) {
      this.columnName = item.name;
      this.transformationsFromToModal = true;
      this.transformation = this.propsTransformationParameter;

      if (this.transformation) {
        this.mountedParametersFromTo();
      }

      this.checkSelectedOption();
    },
    checkSelectedOption() {
      const hasParameters = this.transformation.parameters && this.transformation.parameters.length > 1;

      if (hasParameters) {
        const lastParameterValue = this.transformation.parameters[this.transformation.parameters.length - 1].value;
        // eslint-disable-next-line no-useless-escape
        this.selectedOption = lastParameterValue && lastParameterValue.includes('\"') ? 'original' : 'fixed';
      } else {
        this.selectedOption = 'original';
      }
    },
    close() {
      this.parametersFromTo = [];
      this.parameterElse.isAlphanumeric = false;
      this.parameterElse.isField = true;
      this.parameterElse.name = 'SENAO';
      this.parameterElse.value = '';
      this.transformationsFromToModal = false;
    },
    incrementParameterInTransformations() {
      if (this.transformation.parameters && this.transformation.parameters.length > 0) {
        this.transformation.parameters = [];
        this.transformation.parameters.unshift({
          isAlphanumeric: false,
          isField: true,
          order: 1,
          name: this.columnName,
          value: this.columnName,
        });
      }
    },
    addParameter() {
      this.parametersFromTo.push({
        parameterDE: this.createParameter(true, false, 'DE', 1, ''),
        parameterPARA: this.createParameter(false, false, 'PARA', 1, ''),
      });
    },
    createParameter(isAlphanumeric, isField, name, order, value) {
      return {
        isAlphanumeric,
        isField,
        name,
        order,
        value,
      };
    },
    removeParameter(item) {
      const indexParameter = this.parametersFromTo.indexOf(item);
      this.parametersFromTo.splice(indexParameter, 1);
    },
    onClickSave() {
      if (this.$refs.formFromTo.validate()) {
        this.incrementParameterInTransformations();
        this.adjustFromToInLayout();
        this.adjustElseParameterInLayout();
        this.countIndexParameter = 1;
        this.$emit('on-confirm', this.transformation);
        this.close();
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Preencha os campos para prosseguir');
      }
    },
    mountedParametersFromTo() {
      this.transformation.parameters.forEach(async (parameter, i) => {
        if (i > 0 && i < this.transformation.parameters.length - 1) {
          if ((parameter.order % 2) === 0) {
            const para = this.transformation.parameters.find((element) => element.order === (parameter.order + 1));
            this.parametersFromTo.push({
              parameterDE: this.createParameter(true, parameter.isField, parameter.name, parameter.order, parameter.value),
              parameterPARA: this.createParameter(para.isAlphanumeric, para.isField, para.name, para.order, para.value),
            });
          }
        } else if (i === this.transformation.parameters.length - 1 && !this.transformation.parameters[i].isField) {
          this.parameterElse.isAlphanumeric = this.transformation.parameters[i].isAlphanumeric;
          this.parameterElse.isField = this.transformation.parameters[i].isField;
          this.parameterElse.name = 'SENAO';
          this.parameterElse.order = i + 1;
          this.parameterElse.value = this.transformation.parameters[i].value;
        }
      });
    },
    adjustFromToInLayout() {
      this.parametersFromTo.forEach((element) => {
        element.parameterDE.order = this.countIndexParameter + 1;
        this.countIndexParameter += 1;

        element.parameterPARA.order = this.countIndexParameter + 1;
        this.countIndexParameter += 1;

        this.transformation.parameters.push({ ...element.parameterDE });
        this.transformation.parameters.push({ ...element.parameterPARA });
      });
    },
    adjustElseParameterInLayout() {
      // eslint-disable-next-line no-useless-escape
      let doubleQuotedValue = `\"${this.transformation.field}\"`;
      if (this.selectedOption === 'fixed') {
        this.transformation.parameters.push({
          isAlphanumeric: this.parameterElse.isAlphanumeric,
          isField: false,
          name: 'SENAO',
          order: this.countIndexParameter += 1,
          value: this.parameterElse.value,
        });
      } else {
        if (this.layoutSession.typeIntegrationValue === 'SHIPPING') {
          this.layoutSession.columns.forEach((column) => {
            if (column.name === this.transformation.field) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-useless-escape
              doubleQuotedValue = `\"${column.fromTo}\"`;
            }
          });
        }
        this.transformation.parameters.push({
          isAlphanumeric: false,
          isField: false,
          name: 'SENAO',
          order: this.countIndexParameter += 1,
          value: doubleQuotedValue,
        });
      }
    },
    handleChangedRadioOption(value) {
      this.selectedOption = value;
    },
  },

  created() {
    this.rule = new Rules();
  },
});
</script>
