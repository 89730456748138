<template>
  <v-radio-group v-model="selectedOptions" :column="false" dense class="prima">
    <v-radio
      v-for="(option, index) in options"
      class="mr-5"
      :key="index"
      :label="option.label"
      :value="option.value"
    />
  </v-radio-group>
</template>

<script>
export default ({
  name: 'RadioGroupSelected',

  data: () => ({
    selectedOptions: 'original',
    options: [
      { label: 'Valor Original', value: 'original' },
      { label: 'Valor Fixo', value: 'fixed' },
    ],
  }),

  props: { selectedOption: { type: String } },

  watch: {
    selectedOption: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedOptions = value;
      },
    },

    selectedOptions(newValue) {
      this.$emit('handleChangedRadioOption', newValue);
    },
  },

});
</script>
